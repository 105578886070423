import React from 'react'

const StatusIcon = ({ type = 'available' }) => {

    if (type === 'booked') {
        return (
            <svg width="17" height="17" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="10.5" cy="10.5" r="9.85" stroke="#83B617" strokeWidth="1.3" />
                <path d="M11.7296 9.9303L11.7292 9.93062C11.4354 10.1605 11.0649 10.3248 10.6159 10.4222C10.1639 10.5201 9.79 10.8906 9.79 11.3636V12.4525C9.79 12.7991 10.0709 13.08 10.4175 13.08C10.7641 13.08 11.045 12.7991 11.045 12.4525V11.887C11.045 11.636 11.2264 11.4203 11.4727 11.3441C11.6505 11.2891 11.8282 11.2212 12.0057 11.1405C12.3408 10.9882 12.6406 10.785 12.9047 10.531C13.1694 10.2765 13.3829 9.97128 13.5453 9.61606C13.719 9.24822 13.805 8.82076 13.805 8.335C13.805 7.45381 13.5311 6.74972 12.9793 6.22865C12.4279 5.70782 11.6794 5.45 10.74 5.45C10.2655 5.45 9.84455 5.51562 9.47802 5.64797C9.11285 5.77984 8.79254 5.96272 8.51764 6.19688C8.25337 6.42051 8.02985 6.68473 7.84713 6.98928L7.84712 6.98927L7.84687 6.98971C7.76625 7.12723 7.69451 7.26811 7.63163 7.41235C7.49884 7.71699 7.67467 8.04955 7.97686 8.16389C8.33356 8.29886 8.71127 8.08174 8.87563 7.75418C8.99471 7.51685 9.14767 7.30759 9.33456 7.12613C9.67341 6.80678 10.1251 6.645 10.695 6.645C11.2249 6.645 11.6414 6.7968 11.9502 7.09591L11.9502 7.09592L11.9508 7.09648C12.2591 7.38547 12.415 7.77636 12.415 8.275V8.485C12.415 9.1006 12.1864 9.58045 11.7296 9.9303Z" fill="#83B617" stroke="#83B617" strokeWidth="0.1" />
                <path d="M10.415 16.3295C10.095 16.3295 9.86 16.2495 9.71 16.0895C9.57 15.9295 9.5 15.7195 9.5 15.4595V15.2645C9.5 15.0045 9.57 14.7945 9.71 14.6345C9.86 14.4745 10.095 14.3945 10.415 14.3945C10.735 14.3945 10.965 14.4745 11.105 14.6345C11.255 14.7945 11.33 15.0045 11.33 15.2645V15.4595C11.33 15.7195 11.255 15.9295 11.105 16.0895C10.965 16.2495 10.735 16.3295 10.415 16.3295Z" fill="#83B617" />
            </svg>
        )
    }

    if (type === 'busy') {
        return (
            <svg width="17" height="17" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="10.5" cy="10.5" r="9.85" stroke="#D31313" strokeOpacity="0.9" strokeWidth="1.3" />
                <path d="M6.61133 7L14.3895 14.7782" stroke="#D31313" strokeOpacity="0.9" strokeWidth="1.3" strokeLinecap="round" />
                <path d="M14.3896 7L6.61147 14.7782" stroke="#D31313" strokeOpacity="0.9" strokeWidth="1.3" strokeLinecap="round" />
            </svg>
        )
    }

    if (type === 'square') {
        return (
            <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.65 1C0.65 0.8067 0.8067 0.65 1 0.65H16C16.1933 0.65 16.35 0.806701 16.35 1V16C16.35 16.1933 16.1933 16.35 16 16.35H1C0.806701 16.35 0.65 16.1933 0.65 16V1Z" stroke="#83B617" strokeWidth="1.3" />
                <path d="M5 4.45C4.69624 4.45 4.45 4.69624 4.45 5L4.45 9.95C4.45 10.2538 4.69624 10.5 5 10.5C5.30376 10.5 5.55 10.2538 5.55 9.95L5.55 5.55L9.95 5.55C10.2538 5.55 10.5 5.30376 10.5 5C10.5 4.69624 10.2538 4.45 9.95 4.45L5 4.45ZM12.5 13.05C12.8038 13.05 13.05 12.8038 13.05 12.5V7.55C13.05 7.24624 12.8038 7 12.5 7C12.1962 7 11.95 7.24624 11.95 7.55V11.95H7.55C7.24624 11.95 7 12.1962 7 12.5C7 12.8038 7.24624 13.05 7.55 13.05H12.5ZM4.61109 5.38891L12.1111 12.8889L12.8889 12.1111L5.38891 4.61109L4.61109 5.38891Z" fill="#83B617" />
            </svg>
        )
    }

    return (
        <svg width="17" height="17" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="10.4442" cy="10.4442" r="9.79421" stroke="#83B617" strokeWidth="1.3" />
            <path d="M14.2422 6.64648L10.6614 13.8081C10.5616 14.0076 10.2869 14.0324 10.153 13.8539L6.6464 9.17841" stroke="#83B617" strokeWidth="1.3" strokeLinecap="round" />
        </svg>
    )
}

export default StatusIcon