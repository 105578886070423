import React, { useEffect, useMemo, useRef, useState } from 'react'
import { hexToRgba } from '@/lib/hexToRgb';
import { Group, Line, Rect } from 'react-konva';
import { PolygonData, PolygonType } from '@/api/layer';
import { useProjectStore } from '@/stores/projectStore';
import { useGlobalStore } from '@/stores/globalStore';
import { useRoomStore } from '@/stores/roomStore';
import { useMapStore } from '@/stores/mapStore';
import { linkLayerInfoProperties } from '@/components/Modal/linkProperties';
import { useMetadata } from '@/api/hooks/useMetadata';
import { useLayerInfo } from '@/api/hooks/useLayerInfo';

interface PolygonElementProps {
    id: number
    width: number
    height: number
    polygon: PolygonData
}

export const getMapCoord = (value: number, percent: number) => value * (percent / 100)

const Polygon: React.FC<PolygonElementProps> = ({ id, width, height, polygon }) => {
    const polygonRef = useRef<any>(null)
    const [fill, setFill] = useState(hexToRgba(polygon.fill, polygon.alpha / 100))
    const [stroke, setStroke] = useState(hexToRgba(polygon.stroke, polygon.hover / 100))
    const layer = useGlobalStore(state => state.layer)
    const setLayer = useGlobalStore(state => state.setLayer)
    const addHistory = useRoomStore(state => state.addHistory)
    const setTooltip = useMapStore(state => state.setTooltip)
    const setZoomSeat = useMapStore(state => state.setZoomSeat)

    const path = polygon.polygon.reduce((acc, val) => [...acc, getMapCoord(width, val[0]), getMapCoord(height, val[1])], [])

    const onMouseEnterHandler = (e) => {
        const container = e.target.getStage()?.container()

        if (container) {
            container.style.cursor = "pointer"
        }

        setFill(hexToRgba(polygon.fill, polygon.hover / 100))
        // setStroke(hexToRgba(polygon.stroke, polygon.hover / 100))
    }

    const onMouseLeaveHandler = (e) => {
        const container = e.target.getStage()?.container()

        if (container) {
            container.style.cursor = "default"
        }

        setFill(hexToRgba(polygon.fill, polygon.alpha / 100))
        // setStroke(hexToRgba(polygon.stroke, polygon.alpha / 100))
    }

    const handleClick = (e) => {
        e.evt.preventDefault()
        addHistory(id)
        setLayer(id)
        setTooltip(null)
        setZoomSeat(Number(id))
    }

    const onGroupMouseEnterHandler = React.useCallback((e) => {
        setTooltip(`${id}`)
    }, [setTooltip, id])

    const onGroupMouseLeaveHandler = React.useCallback((e) => {
        setTooltip(null)
    }, [setTooltip])

    useEffect(() => {
        const instance = polygonRef.current
        instance.on("mouseenter", onGroupMouseEnterHandler)
        instance.on("mouseleave", onGroupMouseLeaveHandler)

        return () => {
            instance.off("mouseenter")
            instance.off("mouseleave")
        }
    })

    return (
        <Group
            ref={polygonRef}
        >
            <Line
                stroke={stroke}
                onMouseEnter={onMouseEnterHandler}
                onMouseLeave={onMouseLeaveHandler}
                fill={layer == id ? hexToRgba(polygon.fill, polygon.hover / 100) : fill}
                points={path}
                closed
                onClick={handleClick}
                onTap={handleClick}
            />
            <Rect
                fill="transparent"
                id={"point" + id}
            />
        </Group>
    )
}

export default Polygon