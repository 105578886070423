import { Group, Image, Layer } from "react-konva"
import useImage from 'use-image';
import { ILayerView, LayerService } from '@/api/services/layer.service';
import { memo, useEffect, useMemo, useState } from "react";
import { useMapStore } from "@/stores/mapStore";
import { useGlobalStore } from "@/stores/globalStore";

const MapCoverLayer = ({ view }) => {

    // const home = useGlobalStore(state => state.homela)
    const homeSize = useMapStore(state => state.homeSize)
    const setSize = useMapStore(state => state.setHomeSize)
    const setIsLoading = useMapStore(state => state.setIsLoading)

    const [ ownerId, setOwnderId ] = useState(null)
    const imageURL = useMemo(() => getMapCoverImagePath(view), [ownerId])
    const [ source, status ] = useImage(imageURL)

    useEffect(() => {
        if (source && status === 'loaded') {
            if (homeSize[0] !== source.width || homeSize[1] !== source.height) {
                setSize([source.width, source.height])
            }
            setIsLoading(false)
        } else {
            setIsLoading(true)
        }

        if (!view) {
            setIsLoading(true)
        } else {
            const { image: { owner_id } } = view
            if (owner_id) {
                setOwnderId(owner_id)
            }
        }
    }, [source, status, view])

   if (status === 'loading') return null

    return (
        <Group>
            <Image image={source} />
        </Group>
    )
}

export default memo(MapCoverLayer)

export const getMapCoverImagePath = (view: ILayerView | undefined) => {
    if (!view) return ''

    const urlPath = LayerService.getLayerImage({
        layerId: Number(view.image.owner_id),
        imageName: view.image.image_id,
        extension: view.image.extension
    })

    return urlPath
}