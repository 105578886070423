import useWindowSize from '@/hooks/useWindowSize'
import { useMapStore } from '@/stores/mapStore'
import React, { useRef, useEffect, useState, useLayoutEffect } from 'react'
import Konva from 'konva'
import { Vector2d } from 'konva/lib/types'
import { KonvaEventObject } from 'konva/lib/Node'
import { easeLinear, easeQuadInOut, easeQuadOut, easeSinInOut } from 'd3-ease';
import { useGlobalStore } from '@/stores/globalStore';
import { animated, useSpring } from '@react-spring/konva';
import { useProjectStore } from '@/stores/projectStore'
import { getMapCoord } from './Polygon'
import Emitter from '@/utils/helpers/emitter'
import { Stage } from 'react-konva';
import useResponsive from '@/hooks/useResponsive'
import { useGesture } from '@use-gesture/react'
import styled from 'styled-components'

let initialScale = 1
const scaleBy = 1.25

const isTouchEnabled =
    'ontouchstart' in window ||
    navigator.maxTouchPoints > 0 ||
    // @ts-ignore
    navigator.msMaxTouchPoints > 0;

// Do this to properly handle drag on touch devices.
Konva.hitOnDragEnabled = isTouchEnabled
const ZeroVector: Vector2d = { x: 0, y: 0 }

const MapStage: React.FC = ({ children }) => {
    const stageRef = useRef<any>(null)
    const layerRef = useRef<any>(null)
    const lastCenter = React.useRef<Vector2d | null>(null)
    const lastDist = React.useRef<number>(0)
    
    const [point, setPoint] = useState({x: 0, y: 0, duration: 0 })
    const { width, height } = useWindowSize()

    // data selectors
    const setLayerRef = useMapStore(state => state.setLayerRef)
    const homeSize = useMapStore(state => state.homeSize)
    const isAnimating = useMapStore(state => state.isAnimating)
    const setIsAnimating = useMapStore(state => state.setIsAnimating)
    const prevPosition = useMapStore(state => state.prevPosition)
    const setPrevPosition = useMapStore(state => state.setPrevPosition)
    const layer = useGlobalStore(state => state.layer)
    const list = useProjectStore(state => state.list)
    const zoomSeat = useMapStore(state => state.zoomSeat)
    const setZoomSeat = useMapStore(state => state.setZoomSeat)
    const {isDesktop} = useResponsive()

    const scaleStage = React.useCallback(
        (
            stage: Konva.Stage,
            center: Vector2d,
            stageScale: number,
            centerDelta: Vector2d = ZeroVector,
            batch = false
        ) => {
            const currentScale = layerRef.current.scaleX()
            // local coordinates of center point
            const localCenter = {
                x: (center.x - layerRef.current.x()) / currentScale,
                y: (center.y - layerRef.current.y()) / currentScale,
            }

            const newScale = limitScale(
                stageScale,
                width,
                height,
                homeSize[0],
                homeSize[1]
            )

            const newPos = {
                x: center.x - localCenter.x * newScale + centerDelta.x,
                y: center.y - localCenter.y * newScale + centerDelta.y,
            }
            const boundPos = boundFunc(newPos, newScale, width, height, homeSize[0], homeSize[1])

            if (batch) {
                // stageRef.current.scale({ x: newScale, y: newScale })
                // stageRef.current.position(boundPos)
                // stageRef.current.batchDraw()
                setIsAnimating(true)

                api({
                    x: boundPos.x,
                    y: boundPos.y,
                    scaleX: newScale,
                    scaleY: newScale,
                    config: {
                        duration: 0,
                    },
                    onRest: () => setIsAnimating(false)
                })
            } else {
                
                setIsAnimating(true)
                api({
                    x: boundPos.x,
                    y: boundPos.y,
                    scaleX: newScale,
                    scaleY: newScale,
                    config: {
                        duration: 200,
                        easing: easeLinear,
                    },
                    onRest: () => setIsAnimating(false)
                })
            }
        },
        [width, height, homeSize, moveStage]
    )

    const handleScroll = React.useCallback(
        (event: KonvaEventObject<WheelEvent>) => {
            if (event.evt.defaultPrevented) {
                return
            }

            event.evt.preventDefault()
            const { currentTarget: stage } = event
            if (!(stage instanceof Konva.Stage)) {
                return
            }
            const scale = layerRef.current.scaleX()
            const newScale = event.evt.deltaY < 0 ? scale * scaleBy : scale / scaleBy
            const pointerPos = stage.getPointerPosition()

            scaleStage(
                stage,
                pointerPos ?? ZeroVector,
                newScale
            )
          
        },
        [scaleStage]
    )

    const resize = React.useCallback(() => {
        if (!homeSize[0] || !homeSize[1]) return

        const [stageWidth, stageHeight] = homeSize
        const scaleX = width / stageWidth
        const scaleY = height / stageHeight
        const newScale = Number(Math.max(scaleX, scaleY))

        const pointX = Number(-(stageWidth * newScale - width) / 2)
        const pointY = Number(-(stageHeight * newScale - height) / 2)

        if (Number.isNaN(newScale) || Number.isNaN(pointX) || Number.isNaN(pointY)) return

        const resultScale = limitScale(newScale, width, height, stageWidth, stageHeight)
        initialScale = resultScale

        api({
            x: pointX,
            y: pointY,
            scaleX: resultScale,
            scaleY: resultScale,
            config: {
                duration: 0
            }
        })
        

        if (zoomSeat) {
            moveTo(Number(zoomSeat))
        }

    }, [stageRef, homeSize, width, height])

    const handleTouchMove = React.useCallback(
        (event: KonvaEventObject<TouchEvent>) => {
          if (event.evt.defaultPrevented) {
            return
          }
    
          event.evt.preventDefault();
          const { currentTarget: stage } = event;
    
          if (!(stage instanceof Konva.Stage)) {
            return
          }
    
          if (event.evt.touches.length !== 2) {
            return
          }
    
          if (stage.isDragging()) {
            stage.stopDrag()
          }
    
          const [touch1, touch2]: any = event.evt.touches
          const p1 = { x: touch1.clientX, y: touch1.clientY }
          const p2 = { x: touch2.clientX, y: touch2.clientY }
          const newCenter = getCenter(p1, p2)
          const dist = getDistance(p1, p2)
    
          if (!lastCenter.current) {
            lastCenter.current = newCenter
          }
          if (!lastDist.current) {
            lastDist.current = dist
          }
    
          const centerDelta = {
            x: newCenter.x - lastCenter.current.x,
            y: newCenter.y - lastCenter.current.y,
          }

          const stageScale = (layerRef.current.scaleX() * (dist / lastDist.current))

          scaleStage(stage, lastCenter.current, stageScale, centerDelta, true)
    
          lastDist.current = dist
          lastCenter.current = newCenter
        },
        [scaleStage]
      )

    const multiTouchEnd = React.useCallback(
        (event: KonvaEventObject<TouchEvent>) => {
            lastCenter.current = null
            lastDist.current = 0
        },
        []
    )

    const multiTouchStart = React.useCallback(
        (event: KonvaEventObject<TouchEvent>) => {
            event.evt.preventDefault()

            const { currentTarget: stage } = event;

            if (event.evt.touches.length !== 2) {
                return;
            }

            stage.stopDrag()
        },
        []
    )

    const onMouseMoveHandler = React.useCallback(
        (event) => {
            if (isAnimating) return
            const [stageWidth, stageHeight] = homeSize

            const offsetX = stageWidth * layerRef.current.scaleX() - width
            const offsetY = stageHeight * layerRef.current.scaleX() - height

            const dX = ((event.evt.pageX / width) * offsetX)
            const dY = ((event.evt.pageY / height) * offsetY)

            const newPos = { x: -dX, y: -dY, duration: 2 }

            setPoint(newPos)
            // stage.position(newPos)
        },
        [stageRef, homeSize, width]
    )
    
    const dragBoundFunc = React.useCallback(
        (pos) => boundFunc(pos, layerRef.current.scaleX(), width, height, homeSize[0], homeSize[1]),
        [homeSize, stageRef, width, height]
    )

    const getLength = (path, width, height) => {
        let xMin, xMax, yMin, yMax

        path.forEach(([x, y]) => {
            if (!xMin) xMin = x
            if (!xMax) xMax = x
            if (!yMin) yMin = y
            if (!yMax) yMax = y

            if (xMin > x)  {
                xMin = x
            } else if (xMax < x) {
                xMax = x
            }

            if (yMin > y)  {
                yMin = y
            } else if (yMax < y) {
                yMax = y
            }
        })

        const xLength = Math.abs(getMapCoord(width, xMax) - getMapCoord(width, xMin))
        const yLength = Math.abs(getMapCoord(width, yMax) - getMapCoord(width, yMin))

        return {
            area: Math.max(xLength, yLength),
            xLength,
            yLength,
            xCenter: getMapCoord(width, (xMax + xMin) / 2),
            yCenter: getMapCoord(height, (yMax + yMin) / 2),
        }
    
    }

    const moveTo = (point) => {
        if (!homeSize || !point) return
        
        const stage = stageRef.current
        const el = stage.findOne('#point' + point)

        if (!el) return

        const element = list[point]
        if (!element) return

        if (!prevPosition) {
            const prevScale = layerRef.current.scaleX()

            const stagePos = {
                x: layerRef.current.x(),
                y: layerRef.current.y()
            }
            const newPos =  {
                x: -(homeSize[0] * prevScale - width) / 2,
                y: -(homeSize[1] * prevScale - height) / 2
            }

            setPrevPosition({
                x: stagePos.x ? stagePos.x : newPos.x,
                y: stagePos.y ? stagePos.y : newPos.y,
                scale: layerRef.current.scaleX()
            })
        }
        
        const { area, xCenter, yCenter } = getLength(element.info.polygon.polygon, homeSize[0], homeSize[1])
        
        const pos = { x: xCenter, y: yCenter }
        const pointSize = area
        const stageScale =  homeSize[0] / (pointSize * 10) 
        const sizeScale = homeSize[0] / 3200

        const posPoint =  {
            x: -(pos.x * stageScale) / sizeScale + layerRef.current.width() / 2,
            y: -(pos.y * stageScale) / sizeScale + layerRef.current.height() / 3,
        }

        const boundPos = boundFunc(posPoint, stageScale, width, height, homeSize[0], homeSize[1])

        
        api({
            x: boundPos.x,
            y: boundPos.y,
            scaleX: stageScale / sizeScale,
            scaleY: stageScale / sizeScale,
            config: {
                mass: 1,
                tension: 280,
                friction: 60,
                easing: easeQuadInOut,
            }
        })
        // moveStage(layerRef.current, boundPos, point.duration, stageScale, handleFinishAnimation)

    }

    
    
    useEffect(() => {
        if (isAnimating) return
        const scale = layerRef.current.scaleX()
        const sizeScale = homeSize[0] / 3200

        const scaleAttr = sizeScale * scale

        if (!layer) {
            api({
                x: point.x,
                y: point.y,
                config: {
                    mass: 1,
                    tension: (sizeScale * 150) / scaleAttr,
                    friction: (sizeScale * 100) * scaleAttr,
                    easing: easeQuadInOut,
                }
                // config: {
                //     duration: (homeSize[0] / scale) / 5,
                //     mass: 1,
                //     tension: 180 / scale,
                //     friction: 120 * scale,
                //     easing: easeQuadOut,
                // }
            })
        }
    }, [point])

    useEffect(() => {
        resize()
    }, [width, height, homeSize])

    useEffect(() => {
        if (zoomSeat) {
            setIsAnimating(true)
            moveTo(Number(zoomSeat))
        }
    }, [zoomSeat])

    useEffect(() => {
        const listener = Emitter.addListener('unzoom', unZoom)
        const scaleListener = Emitter.addListener('scale', scaleMap)

        return () => {
            listener.remove()
            scaleListener.remove()
        }
    }, [])


    const unZoom = ({ pos }) => {
        if (!pos) return

        setIsAnimating(true)
        api({
            x: pos.x,
            y: pos.y,
            scaleX: pos.scale,
            scaleY: pos.scale,
            config: {
                mass: 1,
                tension: 280,
                friction: 60,
                easing: easeQuadInOut,
            },
            onRest: () => setIsAnimating(false)
        })
    }

    
    const scaleMap = ({ pos }) => {
        if (!pos) return

        setIsAnimating(true)
        api({
            x: pos.x,
            y: pos.y,
            scaleX: pos.scale,
            scaleY: pos.scale,
            config: {
                duration: 200,
                easing: easeLinear,
            },
            onRest: () => setIsAnimating(false)
        })
    }

    const [style, api] = useSpring(() => ({
        x: 0,
        y: 0,
        scaleX: 1,
        scaleY: 1,
        config: {
            clamp: false,
            duration: 0
        }
    }))

    useLayoutEffect(() => {
        if (layerRef.current) {
            setLayerRef(layerRef.current)
        }
    }, [layerRef])

    return (
        <KonvaWrapper>
            <Stage
                ref={stageRef}
                width={width}
                height={height}
                onWheel={handleScroll}
                onTouchMove={handleTouchMove}
                onTouchEnd={multiTouchEnd}
                onTouchStart={multiTouchStart}
                onMouseMove={onMouseMoveHandler}
                onDragMove={() => {
                    api({
                        x: layerRef.current?.x(),
                        y: layerRef.current?.y(),
                        config: {
                            duration: 0
                        }
                    })
                }}
            >
                <animated.Layer
                    ref={layerRef}
                    draggable={!isDesktop || !prevPosition}
                    dragBoundFunc={dragBoundFunc}
                    {...style}
                >
                    {children}
                </animated.Layer>
            </Stage>
        </KonvaWrapper>
 
    )
}

export default MapStage

const KonvaWrapper = styled.div`
.konvajs-content {
    touch-action: none;
}
`

export const moveStage = (shape: any, { x, y }, duration = 2, scaleTo = 0, onFinish = () => {}) => {
    // if (!Number.isNaN(x) || !Number.isNaN(y) || !Number.isNaN(scale)) return
    // const dur = (scale / initialScale) * duration
    
    const animation = {
        x,
        y,
        duration: duration,
        easing: Konva.Easings['StrongEaseOut'],
        onFinish
    }

    if (scaleTo) {
        // @ts-ignore
        animation.scaleX = scaleTo
        // @ts-ignore
        animation.scaleY = scaleTo
    }

    shape.to(animation)
}

export const getDistance = (p1: Vector2d, p2: Vector2d): number => Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2))
export const getCenter = (p1: Vector2d, p2: Vector2d): Vector2d => ({
    x: (p1.x + p2.x) / 2,
    y: (p1.y + p2.y) / 2,
})
export const boundFunc = (pos, scale, width, height, stageWidth, stageHeight) => {
    const maxWidth = width - stageWidth * scale
    const maxHeight = height - stageHeight * scale

    let x = pos.x
    let y = pos.y

    if (x > 0) {
        x = 0
    }

    if (x < maxWidth) {
        x = maxWidth
    }

    if (y > 0) {
        y = 0
    }

    if (y < maxHeight) {
        y = maxHeight
    }

    return { x, y } as { x: number, y: number }
}

export const limitScale = (scale: number, width: number, height: number, stageWidth: number, stageHeight: number) => {
    const minWScale = width / stageWidth 
    const minHScale = height / stageHeight

    const minScale = Math.max(minWScale, minHScale)

    if (scale < minScale) {
        return minScale
    } else if (scale > 2) {
        return 2
    }
    
    return scale
}